import { Button, Flex } from "antd";
import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useAppContext";

const GoToCartButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 2px solid #f3f4f6;
  display: flex;
  justify-content: center;
  background: #f3f4f6;
  border-top: 1px solid #e9eaee;
`;

const CostumAddButton = styled(Button)`
  max-width: 100% !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;

  &:hover {
    color: white !important;
    border-color: #4f46e5 !important;
  }
`;

export const SetDataAction: React.FC<{ isOrder?: boolean }> = ({
  isOrder = false,
}) => {
  const navigate = useNavigate();
  const { setDeliveryTimeType, serviceData, serviceTime, orderDate, orderTime ,setMealName} =
    useAppContext();

  return (
    <GoToCartButtonWrapper>
      <Flex
        justify="center"
        gap={20}
        style={{
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <CostumAddButton
          onClick={() => {
            setMealName('')
            navigate("/delivery", {
              state: { from: "home", isOrder: isOrder },
            });
            setDeliveryTimeType("FUTURE");
          }}
          disabled={
            (!serviceData || !serviceTime) && (!orderDate || !orderTime)
          }
        >
          <P $size="16" $height="20" $weight="400">
            Wybierz
          </P>
        </CostumAddButton>
      </Flex>
    </GoToCartButtonWrapper>
  );
};
