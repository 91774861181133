import React from "react";
import { Pepper, Leaf, CurrencyDollar } from "@phosphor-icons/react";

export const Icons: React.FC<{ type: "pepper" | "leaf" | "currency" }> = ({
  type,
}) => {
  if (type === "pepper") return <Pepper size={14} color="#c44141" />;
  if (type === "leaf") return <Leaf size={14} color="#149c72"/>;
  if (type === "currency") return <CurrencyDollar size={14} />;
  return <></>;
};
