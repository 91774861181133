import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { useLocation } from "react-router-dom";
import { ucfirst } from "../../../utils";

const GoToDeliveryButtonWrapper = styled.div`
  width: calc(100% - 40px);
  position: fixed;
  top: 20px;
  left: 20px;
  background: white;
  z-index: 1000;
  border-radius: 12px;
  border: 1px solid #d1d5db;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const BranchHeader: React.FC = () => {
  const { selectedBranch } = useAppContext();
  const location = useLocation();

  return (
    <GoToDeliveryButtonWrapper>
      <img src="https://pertuttidt.pl/img/logo.svg" width={70} />
      <P
        $size="20"
        $height="28"
        $weight="500"
        $family="Lato"
        style={{
          textAlign: "center",
        }}
      >
        Witamy w PerTutti
      </P>
      {selectedBranch?.description && location.pathname !== "/" &&(
        <P
          $size="12"
          $height="24"
          $weight="300"
          $family="Lato-Light"
          style={{
            marginBottom: "5px",
          }}
        >
          {ucfirst(selectedBranch?.description)}
        </P>
      )}
    </GoToDeliveryButtonWrapper>
  );
};
