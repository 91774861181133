import { Flex, Form, Input, Select, Spin } from "antd";
import { useAppContext } from "../../../contexts/useAppContext";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";
import {
  getDeliveryPrice,
  getDeliveryZones,
  getGoogleMapsSuggestions,
  getPlaceDetails,
} from "../../../service";
import styled from "styled-components";

const { Option } = Select;
const StyledSelect = styled(Select)<{ $placeholder: boolean }>`
  .ant-select-selection-placeholder {
    color: ${({ $placeholder }) => ($placeholder ? ` black` : '#c0c0c0')};
  }
`;

export const GoogleMaps = () => {
  const {
    selectedBranch,
    apertmenNumber,
    setApertmenNumber,
    deliveryNote,
    setDeliveryNote,
    setDeliveryAdress,
  } = useAppContext();

  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState<{
    label: string;
    value: string;
  } | null>();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<any>();
  const [help, setHelp] = useState<any>();
  const [placeholder, setPlaceholder] = useState<any>(null);

  const storegePlaceId = localStorage.getItem("place_id");
  const [storageMode, setStorageMode] = useState<any>(false);

  useEffect(() => {
    setStorageMode(!!localStorage.getItem("place_id"));
  }, []);

  const { data: zones = [] } = useQuery(
    ["/delivery-zone"],
    () =>
      getDeliveryZones({
        id: selectedBranch?.id,
      }),
    {
      enabled: !!selectedBranch?.id,
    }
  );

  useQuery(
    ["/place-details", storegePlaceId],
    () =>
      getPlaceDetails({
        place_id: storegePlaceId,
      }),
    {
      onSuccess(data) {
        let isInside = [];
        for (const zone of zones) {
          if (
            window.google.maps.geometry.poly.containsLocation(
              new window.google.maps.LatLng(data.geometry),
              new window.google.maps.Polygon({ paths: zone?.zones })
            )
          ) {
            isInside.push(zone);
          }
        }
        const cheapestZone =
          isInside.length > 0 &&
          isInside.reduce((prev, curr) => {
            return curr.price < prev.price ? curr : prev;
          });

        if (cheapestZone) {
          setPlaceholder(data.name);
          setDeliveryAdress({
            ...cheapestZone,
            value: storegePlaceId,
            label: data.name,
          });
          setStatus("success");
          setHelp(
            <>
              <p style={{ margin: 0 }}>
                Koszt dostawy: {(cheapestZone?.price / 100).toFixed(2)} zł
              </p>
              <p style={{ margin: 0 }}>
                Darmowa dostawia przy zamówieniu za min:{" "}
                {(cheapestZone?.freeAbove / 100).toFixed(2)} zł
              </p>
            </>
          );
        } else {
          setDeliveryAdress(null);
          setStorageMode(false);
        }
      },
      enabled: !!storegePlaceId,
    }
  );

  const { isFetching, isLoading } = useQuery(
    ["/google-maps-suggestions", search],
    () => getGoogleMapsSuggestions({ search }),
    {
      onSuccess(data) {
        if (data.status === "OK" && data?.predictions?.length > 0) {
          setOptions(
            data?.predictions
              .filter(
                (e: any) =>
                  e?.types?.includes("premise") ||
                  e?.types?.includes("street_address")
              )
              .map((e: any) => {
                return {
                  value: e.place_id,
                  label: e?.description,
                };
              })
          );
        }
      },
      enabled: search?.length > 5,
    }
  );

  useQuery(
    ["/delivery-price", selectOptions],
    () =>
      getDeliveryPrice({
        place_id: selectOptions?.value,
      }),
    {
      onSuccess(data) {
        let isInside = [];
        for (const zone of zones) {
          if (
            window.google.maps.geometry.poly.containsLocation(
              new window.google.maps.LatLng(data),
              new window.google.maps.Polygon({
                paths: zone?.zones
                  .sort((a: any, b: any) => a?.index - b?.index)
                  .map((e: any) => {
                    return {
                      lat: e.lat,
                      lng: e.lng,
                    };
                  }),
              })
            )
          ) {
            isInside.push(zone);
          }
        }
        const cheapestZone =
          isInside.length > 0 &&
          isInside.reduce((prev, curr) => {
            return curr.price < prev.price ? curr : prev;
          });

        if (cheapestZone && selectOptions) {
          setDeliveryAdress({ ...cheapestZone, ...selectOptions });
          setStatus("success");
          setHelp(
            <>
              <p style={{ margin: 0 }}>
                Koszt dostawy: {(cheapestZone?.price / 100).toFixed(2)} zł
              </p>
              <p style={{ margin: 0 }}>
                Darmowa dostawia przy zamówieniu za min:{" "}
                {(cheapestZone?.freeAbove / 100).toFixed(2)} zł
              </p>
            </>
          );
        } else {
          setDeliveryAdress(null);
          setStatus("error");
          setHelp(
            <>
              <p style={{ margin: 0 }}>Nie obsługujemy dostaw w tym regionie</p>
            </>
          );
        }
      },
      enabled: !!selectOptions && !!selectedBranch?.id,
    }
  );

  const [typingTimeout, setTypingTimeout] = useState<number | null>(null); // Przechowuje timeout
  const handleSearch = (query: string) => {
    setStorageMode(false);
    setDeliveryAdress(null);
    setPlaceholder("");
    setStatus(null);
    setHelp(null);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (query && query.length > 5) {
      setTypingTimeout(
        window.setTimeout(() => {
          setSearch(query);
          setLoading(false);
        }, 1200)
      );
    } else {
      setOptions([]);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item
        label={
          "Adress dostawy (miejscowość, ulica i numer domu - wpisz i wybierz adres z listy)"
        }
        rules={[{ required: true }]}
        required
        validateStatus={status}
        help={help}
      >
        <StyledSelect
          $placeholder={!!placeholder}
          loading={loading || isFetching || isLoading}
          allowClear
          style={{
            height: "40px",
          }}
          onSearch={(e) => {
            setOptions([]);
            if (e?.length > 5) setLoading(true);
            handleSearch(e);
          }}
          showSearch
          placeholder={placeholder ?? "Starego Dębu 60, Kraków"}
          labelInValue
          filterOption={false}
          onChange={(e: any) => e?.value && setSelectOptions(e)}
          notFoundContent={
            loading || isFetching || isLoading ? (
              <Flex
                justify="center"
                style={{
                  padding: 20,
                }}
              >
                <Spin />
              </Flex>
            ) : null
          }
        >
          {options.map((option: any) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </StyledSelect>
      </Form.Item>
      <Form.Item label={"Numer mieszkania (opcjonalne)"} required={false}>
        <Input
          onChange={(e) => setApertmenNumber(e.target.value)}
          value={apertmenNumber}
          style={{
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          placeholder="np. 11"
        />
      </Form.Item>
      <Form.Item label={"Dodaj notatkę (opcjonalne)"} required={false}>
        <Input
          onChange={(e) => setDeliveryNote(e.target.value)}
          value={deliveryNote}
          style={{
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          placeholder="np. Proszę nie uzywac dzwonka"
        />
      </Form.Item>
    </Form>
  );
};
