import { Button, Flex } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useAppContext";

const GoToDeliveryButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #f3f4f6;
  border-top: 1px solid #e9eaee;
  display: flex;
  justify-content: center;
`;

const CostumAddButton = styled(Button)`
  max-width: 100% !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;

  &:hover {
    color: white !important;
    border-color: #4f46e5 !important;
  }
`;

const AdditionPrice: React.FC<{ price: any }> = ({ price }) => {
  const converderdPrice = price / 100;
  return <>{`${converderdPrice.toFixed(2)} zł`}</>;
};

export const GoToDelivery: React.FC = () => {
  const navigate = useNavigate();
  const { cart } = useAppContext();
  const total_ammount = useMemo(
    () =>
      cart
        .map(
          (position: any) =>
            position.cart_positoin.meal_price * position.cart_positoin.meal_quantity
        )
        .reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0),
    [cart]
  );

  return (
    <GoToDeliveryButtonWrapper>
      <Flex
        justify="space-between"
        gap={20}
        style={{
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <CostumAddButton
          onClick={() =>
            navigate("/delivery", { state: { from: "home", isOrder: true } })
          }
        >
          <P $size="16" $height="20" $weight="400">
            Dostawa i podsumowanie ({<AdditionPrice price={total_ammount} />})
          </P>
        </CostumAddButton>
      </Flex>
    </GoToDeliveryButtonWrapper>
  );
};
