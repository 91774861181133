import React, { useState } from "react";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import styled from "styled-components";
import { useQuery } from "react-query";
import { getBranches } from "../../../service";
import { useNavigate, useParams } from "react-router-dom";
import { GoToStatute } from "../../molecules/go-to-statute/GoToStatute";
import { BranchHeader } from "../../organisms/branch-header/BranchHeader";
import { Branch } from "../../atoms/branch/Branch";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import { P } from "../../atoms/paragraph/Paragraph.styled";

const DetailsWrapper = styled.div`
  background: #f3f4f6;
  min-height: 100vh;
  display: flex;
  justify-content: end;
  flex-direction: column;
  padding-bottom: 90px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const BranchesPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setSelectedBranch } = useAppContext();
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);

  const { isFetching } = useQuery(["/branches", id], getBranches, {
    onSuccess(data) {
      if (data.length > 1) {
        setBranches(data);
      } else if (data.length === 1) {
        setSelectedBranch(data[0]);
        navigate(
          data[0]?.config?.welcome_screen?.main_button?.navigate ?? "/menu",
          { state: { from: "home" } }
        );
        return;
      }
      setLoading(false);
    },
  });

  if (!isFetching && !loading) {
    return (
      <>
        <BranchHeader />
        <AnimadetPage>
          <DetailsWrapper>
            <P
              $size="16"
              $height="20"
              $weight="500"
              $family="Lato"
              style={{
                marginBottom: "15px",
              }}
            >
              Wybierz lokal w którym chcesz złozyć zamówienie.
            </P>
            {branches.map((branch: any) => {
              return <Branch branch={branch} />;
            })}
          </DetailsWrapper>
        </AnimadetPage>
        <GoToStatute />
      </>
    );
  }

  return <LoadingScreen />;
};
