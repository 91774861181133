import { Button, Col, Flex, Row } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useAppContext";

const GoToDeliveryButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  background: #f3f4f6;
  border-top: 1px solid #e9eaee;
`;

const CostumAddButton = styled(Button)`
  max-width: 100% !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;

  &:hover {
    color: white !important;
    border-color: #4f46e5 !important;
  }
`;

const CostumAddButton2 = styled(Button)`
  margin-bottom: 20px;
  max-width: 100% !important;
  width: 100% !important;
  height: 48px !important;
  font-size: 18px;
  border-radius: 40px;
  color: #4f46e5;
  background: white;
  border-color: #4f46e5;

  &:hover {
    color: #4f46e595 !important;
    border-color: #4f46e595 !important;
  }
`;

export const GoToMenu: React.FC = () => {
  const navigate = useNavigate();
  const { selectedBranch } = useAppContext();

  return (
    <GoToDeliveryButtonWrapper>
      <Flex
        vertical
        justify="space-between"
        style={{
          maxWidth: "450px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <P
          $size="18"
          $height="24"
          $weight="600"
          $family="Lato"
          style={{
            marginBottom: "15px",
          }}
        >
          Planujesz odwiedzić naszą restaurację?
        </P>
        {selectedBranch.config?.welcome_screen?.additional_buttons?.map(
          (button: any) => {
            return (
              <CostumAddButton2
                onClick={() =>
                  navigate(button?.navigate, { state: { from: "home" } })
                }
              >
                <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                  {button?.text}
                </P>
              </CostumAddButton2>
            );
          }
        )}
        <P
          $size="10"
          $height="20"
          $weight="300"
          $family="Lato-Light"
          style={{
            marginBottom: "8px",
          }}
        >
          Klikając "Przejdz dalej" zgadzasz się na zasady i warunki użytkowania,
          politykę prywatności oraz politykę cookies.
        </P>
        <P
          $size="12"
          $height="20"
          $weight="300"
          $family="Lato-Light"
          style={{
            marginBottom: "15px",
          }}
        >
          System zamówień i rezerwacji obsługuje FlexMENU
        </P>

        <Flex
          justify="space-between"
          gap={20}
          style={{
            maxWidth: "450px",
            width: "100%",
          }}
        >
          <CostumAddButton
            onClick={() =>
              navigate(
                selectedBranch?.config?.welcome_screen?.main_button?.navigate ??
                  "/menu",
                { state: { from: "home" } }
              )
            }
          >
            <P $size="16" $height="20" $weight="400">
              {selectedBranch?.config?.welcome_screen?.main_button?.text ??
                "Przejdz do menu"}
            </P>
          </CostumAddButton>
        </Flex>
      </Flex>
    </GoToDeliveryButtonWrapper>
  );
};
