import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { Labels } from "../../molecules/labels/Labels";
import { SupportedControls } from "../../molecules/supported-controls/SupportedControls";
import { Form, Select } from "antd";

import { AdditionsHeader } from "../../atoms/additions-header/AdditionsHeader";

import useScrollToTop from "../../../hooks/useScrollToTop";

const Image = styled.div<{ $imageUrlS3: string }>`
  display: flex;

  aspect-ratio: 1 / 1;
  padding: 8px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: url(${({ $imageUrlS3 }) => $imageUrlS3});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  margin-bottom: 12px;
`;

export const ProductDetails: React.FC<{
  meal?: any;
  finalPrice: any;
  setFinalPrice: any;
  variant: any;
  extentions: any;
  setVariant: any;
  description: any;
  imageUrlS3: any;
  labels: any;
}> = ({
  extentions,
  meal,
  finalPrice,
  setFinalPrice,
  variant,
  setVariant,
  description,
  imageUrlS3,
  labels,
}) => {
  return (
    <div
    id="product_details"
      style={{
        display: "block",
        position: "relative",
        overflow: "auto",
        width: "100%",
      }}
    >
      <>
        <>
          <div
            style={{
              background: "white",
              borderRadius:
                "var(--space-space-4, 16px) var(--space-space-4, 16px)  0 0",

              paddingRight: "8px",
              paddingLeft: "8px",
              paddingTop: imageUrlS3 ? "8px" : "10px",
            }}
          >
            {imageUrlS3 && <Image $imageUrlS3={imageUrlS3} />}
            <Labels showLabel labels={labels} />
            <P
              $height="20"
              $size="14"
              $weight="300"
              $family="Lato-Light"
              style={{
                marginBottom: "24px",
                marginTop: "12px",
              }}
            >
              {description}
            </P>
          </div>
          <div
            style={
              {
                // marginTop: "30px",
              }
            }
          >
            <Form
              layout="vertical"
              style={
                {
                  // borderBottom: "20px solid #f3f4f6",
                }
              }
            >
              {meal.productBaseVariants?.length > 1 && (
                <Form.Item
                  style={{
                    padding: 8,
                  }}
                >
                  <Select
                    value={variant}
                    onChange={setVariant}
                    options={meal.productBaseVariants?.map((e: any) => {
                      return {
                        value: e.id,
                        label: `${e.name} (${(e.price / 100).toFixed(2)} zł)`,
                      };
                    })}
                  />
                </Form.Item>
              )}

              {extentions &&
                extentions.map((extentionCategory: any) => {
                  return (
                    <>
                      <AdditionsHeader
                        key={extentionCategory.category.id}
                        text={extentionCategory.category.name}
                        category={extentionCategory.category}
                      />
                      <div
                        style={{
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          paddingTop: "12px",
                          paddingBottom: "1px",
                          background: "white",
                        }}
                      >
                        {extentionCategory.category.type === "QUANTITIES" ? (
                          extentionCategory.variants
                            .sort(
                              (a: any, b: any) =>
                                a.menuPosition - b.menuPosition
                            )
                            .map((control: any, index: number) => {
                              return (
                                <SupportedControls
                                  control={{
                                    field_type: "addition",
                                    uuid: `addition-${control.id}`,
                                    field_name: control.name,
                                    price: control.priceExtension,
                                    getFromStorage: false,
                                    minimumRequiredQuantity:
                                      control.productExtension
                                        .minimumRequiredQuantity,
                                    maximumRequiredQuantity:
                                      control.productExtension
                                        .maximumRequiredQuantity,
                                  }}
                                  key={control.id}
                                  finalPrice={finalPrice}
                                  setFinalPrice={setFinalPrice}
                                />
                              );
                            })
                        ) : (
                          <SupportedControls
                            control={{
                              field_type: "additionSelect",
                              uuid: `additionSelect-${extentionCategory.category.id}`,
                              field_name: extentionCategory.category.name,
                              minimumRequiredElements:
                                extentionCategory.category
                                  .minimumRequiredElements,
                              maximumRequiredElements:
                                extentionCategory.category
                                  .maximumRequiredElements,
                              extensions: extentionCategory.variants,
                              type: extentionCategory.category.type,
                            }}
                            finalPrice={finalPrice}
                            setFinalPrice={setFinalPrice}
                          />
                        )}
                      </div>
                    </>
                  );
                })}
            </Form>
          </div>
        </>
      </>
    </div>
  );
};
