import React from "react";
import { P } from "../paragraph/Paragraph.styled";
import styled from "styled-components";
import { Flex } from "antd";
import { useFormContext } from "react-hook-form";

const AdditionsHeaderWrapper = styled(Flex)`
  padding-left: 12px;
  padding-right: 12px;
  background: #f3f4f6;
  height: 45px;
  // padding-top: 20px;
  // padding-bottom: 20px;
`;

export const AdditionsHeader: React.FC<{ text: any; category: any }> = ({
  text,
  category,
}) => {
  const methods = useFormContext();
  const error = !!methods.formState.errors[`additionSelect-${category.id}`];
  const minToSelect =
    category.type === "MULTISELECT" && category.minimumRequiredElements > 0
      ? `(Minimum ${category.minimumRequiredElements})`
      : category.type === "SELECT" && category.minimumRequiredElements > 0
      ? `(Wybierz ${category.minimumRequiredElements})`
      : "";
  return (
    <AdditionsHeaderWrapper
      gap={8}
      align="center"
      justify="space-between"
      id={`additionSelect-${category.id}`}
    >
      <P
        $height="14"
        $size="18"
        $weight="600"
        style={{
          textTransform: "uppercase",
        }}
      >
        {text}
      </P>
      <div
        style={{
          textAlign: "end",
        }}
      >
        <P $height="14" $size="14" $weight="400">
          {minToSelect}
        </P>
        {error && (
          <P
            $height="14"
            $size="14"
            $weight="100"
            $family="Lato-Light"
            style={{
              color: "#ff5152",
            }}
          >
            Wybierz wymaganą ilośc dodatków
          </P>
        )}
      </div>
    </AdditionsHeaderWrapper>
  );
};
