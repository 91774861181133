import { Space } from "antd";
import React from "react";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { Label } from "./Labels.styled";
import { Icons } from "./Icons";

export const Labels: React.FC<{ showLabel?: boolean; labels?: any }> = ({
  showLabel = false,
  labels = [],
}) => {
  return (
    <Space
      wrap
      style={{
        columnGap: "2px",
        zIndex: 1000,
        position: showLabel ? "relative" : "absolute",
        paddingLeft: showLabel ? "4px" : "0px",
        bottom: 4,
        right: 4,
      }}
    >
      {labels?.map((e: any) => {
        return (
          <Label $showLabel={showLabel}>
            {showLabel && (
              <P $size="12" $height="20" $weight="300" $family="Lato-Light">
                {e?.label}
              </P>
            )}
            <Icons type={e?.type} />
          </Label>
        );
      })}
    </Space>
  );
};
