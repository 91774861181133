import React from "react";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { motion } from "framer-motion";
import { Flex, Modal } from "antd";
import { useAppContext } from "../../../contexts/useAppContext";
import facebook from "../../../images/facebook.svg";
import instagram from "../../../images/instagram.svg";
import tiktok from "../../../images/tiktok.svg";
import youtube from "../../../images/youtube.svg";
import { useWindowWidth } from "../../../hooks/useWindowWidth";
import { ucfirst } from "../../../utils";

const headerStyle: React.CSSProperties = {
  height: "auto",
  paddingLeft: "8px",
  paddingRight: "8px",
  paddingTop: "20px",
  paddingBottom: "20px",
  maxWidth: "800px",
  width: "100%",
  background: "white",
};

const icons: any = {
  facebook: facebook,
  instagram: instagram,
  tiktok: tiktok,
  youtube: youtube,
};
type TimeRange = { from: string; till: string };
type Schedule = { [key: string]: TimeRange[] };

const formatScheduleForClient = (schedule: Schedule): any => {
  const dayNames: { [key: string]: string } = {
    monday: "Poniedziałek",
    tuesday: "Wtorek",
    wednesday: "Środa",
    thursday: "Czwartek",
    friday: "Piątek",
    saturday: "Sobota",
    sunday: "Niedziela",
  };

  return Object.entries(schedule).map(([day, timeRanges]) => {
    if (timeRanges.length === 0) {
      return (
        <P
          $size={"14"}
          $weight={"400"}
          $height={"20"}
          style={{
            marginBottom: "15px",
          }}
        >{`${dayNames[day]}: Zamknięte`}</P>
      );
    }
    const hours = timeRanges
      .map(({ from, till }) => `  ${from} - ${till}`)
      .join("\n");
    return (
      <P
        $size={"14"}
        $weight={"400"}
        $height={"20"}
        style={{
          marginBottom: "15px",
        }}
      >{`${dayNames[day]}: ${hours}`}</P>
    );
  });
};

export const Footer: React.FC = () => {
  const { selectedBranch } = useAppContext();
  const width = useWindowWidth();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        borderTop: "1px solid #e5e7eb",
      }}
    >
      <Flex style={headerStyle} justify="start" vertical gap={20}>
        <Flex justify="space-between" align="start" vertical={width < 700}>
          <Flex
            vertical
            gap={5}
            style={{
              marginBottom: "10px",
            }}
          >
            <P $size={"16"} $weight={"600"} $height={"20"}>
              {selectedBranch?.name}
            </P>
            <P $size={"12"} $weight={"300"} $height={"20"} $family="Lato-Light">
              {ucfirst(selectedBranch?.description)}
            </P>
            {Object.keys(selectedBranch?.openingHours).length > 0 && (
              <P
                $size={"13"}
                $weight={"600"}
                $height={"20"}
                $family="Lato-Light"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  Modal.info({
                    title: "Godziny otwarcia",
                    closable: true,
                    content: (
                      <>
                        {formatScheduleForClient(selectedBranch?.openingHours)}
                      </>
                    ),
                  });
                }}
              >
                Godziny przyjmowania zamówień
              </P>
            )}
          </Flex>
          <Flex justify="start" align="center" gap={5}>
            {selectedBranch?.socialMedia &&
              Object.keys(selectedBranch?.socialMedia).map((key) => {
                return (
                  <a href={selectedBranch?.socialMedia?.[key]}>
                    <img src={icons[key]} width={30} />
                  </a>
                );
              })}
          </Flex>
        </Flex>

        <Flex>
          <P $size={"14"} $weight={"300"} $height={"20"} $family="Lato-Light">
            Zarządzaj sprzedarzą i rezerwacjami online z{" "}
            <a
              href="https://www.proste.menu"
              style={{
                color: "black",
                textDecoration: "underline",
              }}
            >
              proste.menu
            </a>
            {' '}- to takie proste
          </P>
        </Flex>

        <Flex
          justify="space-between"
          align="center"
          style={{
            paddingBottom: "60px",
          }}
        >
          <Flex gap={8}>
            <a
              href="https://docs.google.com/document/d/1WZeUTRL7JH4IArkRUJFxkZ8L5b17piInmUVRKPr8j3s/edit?usp=sharing"
              style={{
                color: "black",
                textDecoration: "underline",
              }}
            >
              <P
                $size={"10"}
                $weight={"300"}
                $height={"20"}
                $family="Lato-Light"
              >
                Polityka strony
              </P>
            </a>
            <a
              href="https://docs.google.com/document/d/16lvjl3dRiutjWv150zGMiHJ60q0KdE9WJ6nbyF6qii4/edit?usp=sharing"
              style={{
                color: "black",
                textDecoration: "underline",
              }}
            >
              <P
                $size={"10"}
                $weight={"300"}
                $height={"20"}
                $family="Lato-Light"
              >
                Regulamin strony
              </P>
            </a>
          </Flex>
          <div>
            <P $size={"10"} $weight={"300"} $height={"20"} $family="Lato-Light">
              Created by{" "}
              <a
                href="https://proste.menu"
                style={{
                  color: "black",
                  textDecoration: "underline",
                }}
              >
                proste.menu
              </a>
            </P>
          </div>
        </Flex>
      </Flex>
    </div>
  );
};
