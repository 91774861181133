import React from "react";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import styled from "styled-components";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { useLocation, useNavigate } from "react-router-dom";

const DetailsWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 50px;
  background: white;
  min-height: calc(100vh - 153px);
`;

export const VariantSelect: React.FC = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const location = useLocation();
  const meal = location.state?.meal;

  return (
    <>
      <AnimadetPage>
        <DetailsWrapper>
          {meal?.productBaseVariants?.map((e: any) => {
            return (
              <div
                style={{
                  padding: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/details/${meal.id}`, {
                    state: { from: "home", variant: e, meal: meal },
                  });
                }}
              >
                <span>{e.name}</span>
                <span>{e.price}</span>
              </div>
            );
          })}
        </DetailsWrapper>
      </AnimadetPage>
    </>
  );
};
