import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SingleMeal } from "../../molecules/single-meal/SingleMeal";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { useQuery } from "react-query";
import { getResourceCategories, getServices } from "../../../service";
import { motion } from "framer-motion";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { ServicesCategoriesSlider } from "../../molecules/services-categories-slider/ServicesCategoriesSlider";
import { useNavigate } from "react-router-dom";
import { SingleServiceCategory } from "../../molecules/single-service-catagory/SingleServiceCategory";
import { scrollToPosition } from "../../../utils";

export const ServicesPage: React.FC = () => {
  useScrollToTop();
  const navigate = useNavigate();

  const [preventScroll, setPreventScroll] = useState(false);

  const [preventAnimation, setPreventAnimation] = useState(false);

  const { scrollTop } = useScrollFromTop();
  const { cart, setCart, setScroll, selectedBranch, scroll } = useAppContext();
  const { data: categories = [], isFetching } = useQuery(
    ["/services", selectedBranch],
    () => getServices(selectedBranch?.id),
    {
      enabled: !!selectedBranch,
    }
  );

  const {
    data: resourceCategories = [],
    isFetching: isFetchingResourceCategories,
  } = useQuery(["/resource-categories"], () => getResourceCategories());

  const animations = {
    initial: { opacity: 0, x: "10vw" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
  };

  useEffect(() => {
    setCart([]);
  }, []);

  useEffect(() => {
    if (categories?.length > 0) {
      scroll && scrollToPosition(scroll);
      setScroll(0);
    }
  }, [categories]);
  
  return (
    <>
      <ServicesCategoriesSlider
        setPreventAnimation={setPreventAnimation}
        setPreventScroll={setPreventScroll}
      />
      {!isFetching && !isFetchingResourceCategories && categories ? (
        <AnimadetPage>
          <div
            style={{
              paddingBottom: cart.length > 0 ? "100px" : "0px",
            }}
          >
            <motion.div
              variants={animations}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{
                paddingTop:
                  scrollTop > 64 && !preventAnimation ? "40px" : "0px",
              }}
            >
              {categories
                .sort((a: any, b: any) => a.menuPosition - b.menuPosition)
                .map((category: any) => {
                  if (category.services?.length > 0)
                    return (
                      <SingleServiceCategory
                        category={category}
                        preventScroll={preventScroll}
                        resourceCategories={resourceCategories}
                      />
                    );
                })}
            </motion.div>
          </div>
        </AnimadetPage>
      ) : (
        <LoadingScreen />
      )}
      {/* {cart.length > 0 && <GoToCartButton />} */}
    </>
  );
};
