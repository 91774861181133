import { Button, Flex } from "antd";
import styled from "styled-components";
import { P } from "../paragraph/Paragraph.styled";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useController, useFormContext } from "react-hook-form";
import { useEffect } from "react";

const AdditionWrapper = styled.div`
  max-height: 40px;
  height: 40px;
  margin-bottom: 18px;
`;

const QuantityWrapper = styled.div`
  max-width: 120px !important;
  width: 120px !important;
  min-width: 120px !important;
`;

const CostumButton = styled(Button)`
  max-width: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  max-height: 40px !important;
  height: 40px !important;
`;

export const AdditionPrice: React.FC<{ price: any }> = ({ price }) => {
  const converderdPrice = price / 100;
  return <>{`+ ${converderdPrice.toFixed(2)} zł`}</>;
};
// minimumRequiredQuantity
// maximumRequiredQuantity
export const Addition: React.FC<{
  control: any;
  setFinalPrice?: any;
}> = ({ control, setFinalPrice }) => {
  useController({ name: `${control.uuid}` });
  const methods = useFormContext();
  const value = methods.watch(`${control.uuid}`);
  useEffect(() => {
    setFinalPrice((state: any) => {
      if (value) {
        return [
          ...state.filter((e: any) => e.id !== control.uuid),
          {
            id: control.uuid,
            value: control.price * value,
          },
        ];
      } else {
        return [...state.filter((e: any) => e.id !== control.uuid)];
      }
    });
  }, [value]);

  // useEffect(() => { d
  //   methods.setValue(`${control.uuid}`, control?.minimumRequiredQuantity ?? 10);
  // }, []);

  return (
    <AdditionWrapper>
      <Flex
        style={{
          maxHeight: "40px",
        }}
        justify="space-between"
      >
        <div>
          <P
            $height="18"
            $size="14"
            $weight="400"
            style={{
              marginBottom: "4px",
            }}
          >
            {control.field_name}
          </P>
          <P $height="14" $size="12" $weight="300" $family="Lato-Light">
            <AdditionPrice price={control.price}></AdditionPrice>
          </P>
        </div>
        <QuantityWrapper>
          <Flex
            style={{
              maxHeight: "40px",
            }}
            justify={value && value > 0 ? "space-between" : "end"}
            align="center"
          >
            {value > 0 && (
              <>
                <CostumButton
                  onClick={() => {
                    methods.setValue(`${control.uuid}`, value - 1);
                  }}
                  danger
                  shape="circle"
                  icon={<MinusOutlined />}
                  disabled={value <= control?.minimumRequiredQuantity}
                />
                <P $height="24" $size="18" $weight="400">
                  {value}
                </P>
              </>
            )}
            <CostumButton
              onClick={() => {
                methods.setValue(`${control.uuid}`, value + 1);
              }}
              style={{
                borderColor: "#4F46E5",
              }}
              shape="circle"
              disabled={value >= control?.maximumRequiredQuantity}
              icon={
                <PlusOutlined
                  style={{
                    color: "#4F46E5",
                  }}
                />
              }
            />
          </Flex>
        </QuantityWrapper>
      </Flex>
    </AdditionWrapper>
  );
};
