import { Button, Flex } from "antd";
import styled from "styled-components";
import { P } from "../paragraph/Paragraph.styled";
import {
  MinusCircleFilled,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useController, useForm, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { method } from "lodash";

const AdditionWrapper = styled.div`
  margin-bottom: 18px;
`;

const QuantityWrapper = styled.div`
  max-width: 120px !important;
  width: 120px !important;
  min-width: 120px !important;
`;

const CostumButton = styled(Button)`
  max-width: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  max-height: 40px !important;
  height: 40px !important;
`;

export const AdditionPrice: React.FC<{ price: any }> = ({ price }) => {
  const converderdPrice = price / 100;
  return <>{`+ ${converderdPrice.toFixed(2)} zł`}</>;
};
// minimumRequiredQuantity
// maximumRequiredQuantity
export const AdditionSelect: React.FC<{
  control: any;
  setFinalPrice?: any;
}> = ({ control, setFinalPrice }) => {
  useController({ name: `${control.uuid}` });
  const methods = useFormContext();
  const value = methods.watch(`${control.uuid}`);
  const error = !!methods.formState.errors[control.uuid];

  useEffect(() => {
    setFinalPrice((state: any) => {
      if (value) {
        return [
          ...state.filter((e: any) => e.id !== control.uuid),
          {
            id: control.uuid,
            value: Array.isArray(value)
              ? value.reduce((acc, e) => {
                  return acc + e?.priceExtension;
                }, 0)
              : 0,
          },
        ];
      } else {
        return [...state.filter((e: any) => e.id !== control.uuid)];
      }
    });
    methods.clearErrors()
  }, [value]);

  useEffect(() => {
    if (!Array.isArray(value)) methods.setValue(`${control.uuid}`, []);
  }, []);

  return (
    <AdditionWrapper>
      {control?.extensions
        .sort((a: any, b: any) => a.menuPosition - b.menuPosition)
        .map((e: any) => {
          return (
            <Flex
              style={{
                marginTop: "10px",
              }}
              justify="space-between"
            >
              <div>
                <P
                  $height="18"
                  $size="14"
                  $weight="400"
                  style={{
                    marginBottom: "4px",
                  }}
                >
                  {e.productExtension.name}
                </P>
                <P $height="14" $size="12" $weight="300" $family="Lato-Light">
                  <AdditionPrice price={e.priceExtension}></AdditionPrice>
                </P>
              </div>
              <QuantityWrapper>
                <Flex
                  style={{
                    maxHeight: "40px",
                  }}
                  justify="end"
                  align="center"
                >
                  <CostumButton
                    onClick={() => {
                      if (value?.find((element: any) => element.id === e.id)) {
                        methods.setValue(
                          `${control.uuid}`,
                          value?.filter((element: any) => element.id !== e.id)
                        );
                      } else {
                        if (control.type === "SELECT") {
                          methods.setValue(`${control.uuid}`, [
                            {
                              id: e.id,
                              name: e.name,
                              priceExtension: e.priceExtension,
                            },
                          ]);
                        } else if (control.type === "MULTISELECT") {
                          const newValue = [
                            {
                              id: e.id,
                              name: e.name,
                              priceExtension: e.priceExtension,
                            },
                            ...value,
                          ];
                          if (
                            newValue.length <= control.maximumRequiredElements
                          )
                            methods.setValue(`${control.uuid}`, [
                              {
                                id: e.id,
                                name: e.name,
                                priceExtension: e.priceExtension,
                              },
                              ...value,
                            ]);
                        }
                      }
                    }}
                    style={{
                      borderColor: error ? "" : "#4F46E5",
                    }}
                    danger={error}
                    shape="circle"
                    icon={
                      value?.find((element: any) => element.id === e.id) && (
                        <MinusCircleFilled
                          style={{
                            color: "#4F46E5",
                            background: "#4F46E5",
                            borderRadius: "100%",
                          }}
                        />
                      )
                    }
                  />
                </Flex>
              </QuantityWrapper>
            </Flex>
          );
        })}
    </AdditionWrapper>
  );
};
