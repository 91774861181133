import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { createMealOptionFrontView, objectEquals } from "./AddMealAction.utils";
import { useAppContext } from "../../../contexts/useAppContext";
import { v4 as uuidv4 } from "uuid";
import { scrollToElementById } from "../../../utils";
const AddMealActionWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #f3f4f6;
  border-top: 1px solid #e9eaee;
  display: flex;
  justify-content: center;
`;

const CostumButton = styled(Button)`
  max-width: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  max-height: 40px !important;
  height: 40px !important;
`;

const CostumAddButton = styled(Button)`
  max-width: 100% !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;

  &:hover {
    color: white !important;
    border-color: #4f46e5 !important;
  }
`;

const CostumAddButtonDanger = styled(Button)`
  max-width: 100% !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
`;

const AdditionPrice: React.FC<{ price: any }> = ({ price }) => {
  const converderdPrice = price / 100;
  return <>{`${converderdPrice.toFixed(2)} zł`}</>;
};

export const AddMealAction: React.FC<{
  finalPrice: any;
  controls: any;
  variant: any;
  id: any;
}> = ({ finalPrice, controls, variant, id }) => {
  const navigate = useNavigate();
  const { setCart, isOpen, setSelectedProduct } = useAppContext();
  const [quantity, setQuantity] = useState(1);
  const methods = useFormContext();
  const queryClient = useQueryClient();

  const details: any = queryClient.getQueryData([
    "/product-details",
    id,
    variant,
  ]);

  const final_price = useMemo(
    () =>
      finalPrice
        .map((price: any) => price.value)
        .reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0),
    [finalPrice]
  );
  const [block, setBlock] = useState(false);
  const submit = () => {
    setBlock(true);
    methods.handleSubmit(
      (data) => {
        const meal_name =
          details.name +
          " " +
          details?.productBaseVariants.find((e: any) => e.id === variant)?.name;
        const meal_id = id;
        const meal_quantity = quantity;
        const meal_options = createMealOptionFrontView(
          [{ section: { name: "additions" }, controls }],
          data
        );

        // const meal_options: any[] = [];

        const meal_price = final_price;
        if (meal_name && meal_id && meal_quantity && meal_options) {
          const meal_object = {
            local_id: uuidv4(),
            cart_positoin: {
              meal_name,
              meal_id,
              meal_quantity,
              meal_options,
              meal_price,
            },
          };

          setCart((cart: any) => {
            let flag = false;
            const newCart = JSON.parse(JSON.stringify(cart));
            newCart.forEach((element: any) => {
              if (
                element.cart_positoin.meal_id ===
                  meal_object.cart_positoin.meal_id &&
                element.cart_positoin.meal_price ===
                  meal_object.cart_positoin.meal_price
              ) {
                if (
                  objectEquals(
                    element.cart_positoin.meal_options,
                    meal_object.cart_positoin.meal_options
                  )
                ) {
                  const meal = newCart.find(
                    (e: any) => e.local_id === element.local_id
                  );
                  meal.cart_positoin.meal_quantity =
                    meal.cart_positoin.meal_quantity + quantity;

                  flag = true;
                }
              }
            });
            if (flag) {
              return [...newCart];
            } else {
              return [...newCart, meal_object];
            }
          });
          message.success("Dodano do koszyka");
          navigate("/menu", { state: { from: "home" } });
          setQuantity(1);
        }
        setSelectedProduct(null);
      },
      (error) => {
        console.log("error", error);
        message.error("Uzupełnij wszystkie wymagane pola");
        Object.keys(error)?.[0] &&
          scrollToElementById(Object.keys(error)[0], "product_details");
      }
    )();
    setTimeout(() => {
      setBlock(false);
    }, 200);
  };

  return (
    <AddMealActionWrapper>
      <Flex
        justify="space-between"
        gap={20}
        style={{
          maxWidth: "350px",
          width: "100%",
        }}
      >
        <Flex
          style={{
            maxHeight: "40px",
            width: "70%",
          }}
          justify="space-between"
          align="center"
        >
          <CostumButton
            disabled={quantity === 1}
            onClick={() => {
              setQuantity((state) => {
                return state - 1;
              });
            }}
            danger
            shape="circle"
            icon={<MinusOutlined />}
          />
          <P $height="24" $size="18" $weight="400">
            {quantity}
          </P>
          <CostumButton
            onClick={() => {
              setQuantity((state) => {
                return state + 1;
              });
            }}
            style={{
              borderColor: "#4F46E5",
            }}
            shape="circle"
            icon={
              <PlusOutlined
                style={{
                  color: "#4F46E5",
                }}
              />
            }
          />
        </Flex>
        <Flex
          style={{
            maxHeight: "48px",
            width: "100%",
          }}
          justify="space-between"
          align="center"
        >
          <CostumAddButton
            disabled={block || !isOpen}
            loading={block}
            onClick={submit}
            icon={
              <PlusOutlined
                style={{
                  color: "#ffffff",
                }}
              />
            }
          >
            <AdditionPrice price={final_price * quantity} />
          </CostumAddButton>
        </Flex>
      </Flex>
    </AddMealActionWrapper>
  );
};
