import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useQuery, useQueryClient } from "react-query";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { scrollToElementById } from "../../../utils";
import { LeftOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { getServices, getServicesCategory } from "../../../service";
import { useAppContext } from "../../../contexts/useAppContext";

const TopBar = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  padding-top: 5px;
  justify-content: center; /* center content horizontally */
`;

const ScrollableFixedBarWrapper = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(to bottom, white 90%, #f5f5f5 10%);
  padding-left: 2px;
  padding-right: 2px;

  -webkit-box-shadow: inset 0px -12px 0px -8px #e5e7eb;
  -moz-box-shadow: inset 0px -12px 0px -8px #e5e7eb;
  box-shadow: inset 0px -12px 0px -8px #e5e7eb;
`;

export const ServicesCategoriesSlider: React.FC<{
  setPreventAnimation: any;
  setPreventScroll: any;
}> = ({ setPreventAnimation, setPreventScroll }) => {
  const { setSelectedCategory, selectedCategory } = useAppContext();
  const { scrollTop } = useScrollFromTop();

  const { data: categories = [], isFetching } = useQuery(
    ["/services-categories"],
    getServicesCategory,
    {
      enabled: !!true,
      onSuccess(data) {
        setSelectedCategory(data?.sort((a: any, b: any) => a.menuPosition - b.menuPosition)?.[0]);
      },
    }
  );

  useEffect(() => {
    setPreventAnimation(!(categories?.length > 1));
  }, [categories]);

  useEffect(() => {
    const tabs = document.querySelectorAll(".scrollable-tabs-container a")!;
    const rightArrow = document.querySelector(
      ".scrollable-tabs-container .right-arrow svg"
    )!;
    const leftArrow = document.querySelector(
      ".scrollable-tabs-container .left-arrow svg"
    )!;
    const tabsList = document.querySelector(".scrollable-tabs-container ul")!;
    const leftArrowContainer = document.querySelector(
      ".scrollable-tabs-container .left-arrow"
    )!;
    const rightArrowContainer = document.querySelector(
      ".scrollable-tabs-container .right-arrow"
    )!;

    const removeAllActiveClasses = () => {
      tabs.forEach((tab) => {
        tab.classList.remove("active");
      });
    };

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        removeAllActiveClasses();
        tab.classList.add("active");
      });
    });

    const manageIcons = () => {
      if (tabsList.scrollLeft >= 20) {
        leftArrowContainer.classList.add("active");
      } else {
        leftArrowContainer.classList.remove("active");
      }

      let maxScrollValue = tabsList.scrollWidth - tabsList.clientWidth - 20;
      // console.log("scroll width: ", tabsList.scrollWidth);
      // console.log("client width: ", tabsList.clientWidth);

      if (tabsList?.scrollLeft >= maxScrollValue) {
        rightArrowContainer.classList.remove("active");
      } else {
        rightArrowContainer.classList.add("active");
      }
    };

    rightArrow?.addEventListener("click", () => {
      tabsList.scrollLeft += 200;
      manageIcons();
    });

    leftArrow?.addEventListener("click", () => {
      tabsList.scrollLeft -= 200;
      manageIcons();
    });

    tabsList?.addEventListener("scroll", manageIcons);

    // const [dragging, setDragging] = useState(false);
    // const drag = (e: any) => {
    //   if (!dragging) return;
    //   tabsList.classList.add("dragging");
    //   tabsList.scrollLeft -= e.movementX / 4;
    // };

    // tabsList?.addEventListener("mousedown", () => {
    //   setDragging(true)
    // });

    // tabsList?.addEventListener("mousemove", drag);

    // document.addEventListener("mouseup", () => {
    //   setDragging(false)
    //   tabsList?.classList.remove("dragging");
    // });
  }, [isFetching]);

  if (isFetching) return null;
  if (categories?.length > 1)
    return (
      <TopBar>
        <ScrollableFixedBarWrapper
          style={{
            position: scrollTop > 64 ? "fixed" : "relative",
            zIndex: 1000,
            top: 0,
            paddingTop: scrollTop > 64 ? "7px" : "2px",
          }}
        >
          <div className="scrollable-tabs-container">
            <div
              className="left-arrow"
              style={{
                marginTop: "-2px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
                style={{
                  width: "32px",
                  height: "32px",
                }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </div>

            <ul>
              {categories
                .sort((a: any, b: any) => a.menuPosition - b.menuPosition)
                .map((element: any, index: number) => {
                  return (
                    <li
                      draggable="false"
                      className={
                        selectedCategory?.id === element?.id ? "active" : ""
                      }
                      onClick={() => {
                        setPreventScroll(true);
                        setSelectedCategory(element);
                        scrollToElementById(element.id);
                        setTimeout(() => setPreventScroll(false), 300);
                      }}
                    >
                      <a className={index === 0 ? "active" : ""}>
                        {element.name}
                      </a>
                    </li>
                  );
                })}
            </ul>

            <div
              className="right-arrow active"
              style={{
                marginTop: "-2px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
                style={{
                  width: "32px",
                  height: "32px",
                }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        </ScrollableFixedBarWrapper>
      </TopBar>
    );

  return null;
};
