import { Button, Flex, notification } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useAppContext";
import { useMutation } from "react-query";
import {
  createPayments,
  createProductOrder,
  createServiceOrder,
} from "../../../service";
import dayjs from "dayjs";

const GoToDeliveryButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  background: #f3f4f6;
  border-top: 1px solid #e9eaee;
`;

const CostumAddButton = styled(Button)`
  max-width: 100% !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: white !important;
    border-color: #4f46e5 !important;
  }
`;

const AdditionPrice: React.FC<{ price: any }> = ({ price }) => {
  const converderdPrice = price / 100;
  return <>{`${converderdPrice.toFixed(2)} zł`}</>;
};

export const GoToPay: React.FC<{ selectedOptionObject: any }> = ({
  selectedOptionObject,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    cart,
    serviceData,
    serviceTime,
    selectedBranch,
    additionalSections,
    deliveryAdress,
    selectedDeliveryMethod,
    deliveryTimeType,
    orderDate,
    orderTime,
    apertmenNumber,
    deliveryNote,
    email,
    name,
    number,
    deliveryType,
    serviceResurces,
    isOpen,
  } = useAppContext();

  const createResourcesObject = (resources: any) => {
    const keys = Object.keys(serviceResurces).filter(
      (key) => serviceResurces[key]?.value > 0
    );
    const newResurcesObject: any = {};
    keys.forEach((element) => {
      if (serviceResurces[element]?.value) {
        newResurcesObject[element] = {
          ...serviceResurces[element],
          categoryId: element,
        };
      }
    });
    return newResurcesObject;
  };

  const total_ammount = useMemo(
    () =>
      cart
        .map(
          (position: any) =>
            position.cart_positoin.meal_price *
            position.cart_positoin.meal_quantity
        )
        .reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0),
    [cart]
  );

  const isOrder = !!location?.state?.isOrder;

  const {
    mutateAsync: mutateAsynccreatePayments,
    isLoading: createPaymentsLoading,
  } = useMutation(createPayments, {
    onSuccess: (response) => {
      if (response.status === 201) {
        if (
          !!response.data.id &&
          (response.data.status === "CONFIRMED" ||
            response.data.status === "PENDING")
        ) {
          if (response.data.content?.transactionData?.link) {
            const targetUrl = response.data.content.transactionData?.link;
            window.location.href = targetUrl;

            setTimeout(() => {
              if (window.location.href !== targetUrl) {
                navigate("/to-pay", {
                  state: { from: "home", url: targetUrl },
                });
              } 
            }, 1000); // Sprawdź po 1 sekundzie
          } else {
            navigate("/thank-you", { state: { from: "home" } });
          }
        }
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const { mutateAsync, isLoading: createIsLoading } = useMutation(
    createServiceOrder,
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          mutateAsynccreatePayments({
            amount: response.data.price,
            content: {},
            paymentMethodId: selectedOptionObject?.id,
            serviceOrderId: response.data.id,
            email: email,
          });
        }
      },
      onError: () => {
        notification.error({
          message: "Błąd",
          style: {
            background: "#ff4d4f30",
          },
        });
      },
    }
  );

  const {
    mutateAsync: productOrderMutateAsync,
    isLoading: productOrderMutateLoading,
  } = useMutation(createProductOrder, {
    onSuccess: (response) => {
      if (response.status === 201) {
        mutateAsynccreatePayments({
          amount: response.data.price,
          content: {},
          paymentMethodId: selectedOptionObject?.id,
          productOrderId: response.data.id,
          email: email,
        });
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const submit = () => {
    if (isOrder) {
      productOrderMutateAsync({
        price: total_ammount + (deliveryAdress?.price ?? 0),
        details: {
          cart,
          payments: selectedOptionObject?.name,
          paymentsType: selectedOptionObject?.type,
          deliveryTimeType: deliveryTimeType,
          deliveryZone: deliveryAdress?.name ?? "",
          deliveryLabel: deliveryAdress?.label ?? "",
          deliveryPrice: deliveryAdress?.price ?? "",
          deliveryPlaceId: deliveryAdress?.value ?? "",
          orderDate: `${orderDate} ${orderTime}`,
          apertmenNumber: apertmenNumber,
          deliveryNote: deliveryNote,
          deliveryType: deliveryType,
          additionalSections: additionalSections,
          deliveryMethodLabel: selectedDeliveryMethod?.name,
        },
        email: email,
        clientName: name,
        telNumber: number,
        deliveryMethodId: selectedDeliveryMethod?.id,
        status:
          selectedOptionObject?.type === "OFFLINE"
            ? "IN_QUEUE"
            : "WAITING_FOR_PAYMENT",
        companyBranchId: selectedBranch?.id,
      });
    } else {
      mutateAsync({
        price: total_ammount,
        startTime: dayjs.utc(
          `${serviceData} ${serviceTime}`,
          "YYYY-MM-DD HH:mm"
        ),
        details: {
          additionalSections: additionalSections,
          cart,
          payments: selectedOptionObject?.name,
          paymentsType: selectedOptionObject?.type,
          deliveryTime: deliveryTimeType,
          resources: createResourcesObject(serviceResurces),
        },
        email: email,
        clientName: name,
        telNumber: number,
        serviceId: cart[0].cart_positoin.id,
        companyBranchId: selectedBranch.id,
      });
    }
  };

  return (
    <GoToDeliveryButtonWrapper>
      <Flex
        vertical
        justify="space-between"
        style={{
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <Flex
          justify="space-between"
          gap={20}
          style={{
            maxWidth: "450px",
            width: "100%",
          }}
        >
          <CostumAddButton
            onClick={submit}
            loading={
              createIsLoading ||
              createPaymentsLoading ||
              productOrderMutateLoading
            }
            disabled={
              !selectedOptionObject ||
              createIsLoading ||
              createPaymentsLoading ||
              productOrderMutateLoading ||
              !isOpen
            }
          >
            <P $size="16" $height="20" $weight="400">
              {isOrder ? (
                <>
                  Zamawiam (
                  {
                    <AdditionPrice
                      price={total_ammount + (deliveryAdress?.price ?? 0)}
                    />
                  }
                  )
                </>
              ) : (
                <>
                  Rezerwuję (
                  {
                    <AdditionPrice
                      price={total_ammount + (deliveryAdress?.price ?? 0)}
                    />
                  }
                  )
                </>
              )}
            </P>
          </CostumAddButton>
        </Flex>
      </Flex>
    </GoToDeliveryButtonWrapper>
  );
};
