import { Flex, Modal, Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { useAppContext } from "../../../contexts/useAppContext";
import { useNavigate } from "react-router-dom";
import { ProductDetails } from "../../pages/dish-details-page/ProductDetails";
import { AddMealAction } from "../../molecules/add-meal-action/AddMealAction";
import { useQuery } from "react-query";
import { getProductDetails } from "../../../service";
import {
  createFormInitialValue,
  createShema,
  joiResolver,
  ucfirst,
} from "../../../utils";
import { FormProvider, useForm } from "react-hook-form";

const StyledModal = styled(Modal)<{ $height: number }>`
  .ant-modal-body {
    max-height: calc(${({ $height }) => $height}px - 170px);
    min-height: calc(${({ $height }) => $height}px - 470px);
    overflow: hidden;

    display: flex;
    align-items: stretch;
  }
  .ant-modal-content {
    padding: 0;
    overflow: hidden;
  }
`;

export const ProductDetailsModal: React.FC = () => {
  const { setSelectedProduct, selectedProduct, isOpen } = useAppContext();
  console.log("window.visualViewport.height", window?.visualViewport?.height);
  const [variant, setVariant] = useState(null);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (selectedProduct)
      setVariant(selectedProduct?.productBaseVariants[0]?.id ?? null);
  }, [selectedProduct]);

  useEffect(() => {
    window?.visualViewport?.addEventListener("resize", () => {
      setHeight(window?.visualViewport?.height ?? window.innerHeight);
    });
  }, []);

  const { setMealName } = useAppContext();
  const [finalPrice, setFinalPrice] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [controls, setControls] = useState<any>([]);
  const [description, setDescription] = useState("");
  const [extentions, setExtentions] = useState([]);
  const [imageUrlS3, setImageUrlS3] = useState("");

  const { isFetching } = useQuery(
    ["/product-details", selectedProduct?.id, variant],
    () => getProductDetails({ id: selectedProduct.id, variant: variant }),
    {
      enabled: !!selectedProduct && !!variant,
      onSuccess(data) {
        const options = data.options ?? [];
        // const additionals1 = data.additionals;
        // const additionals =
        //   data.additionals.map((addition: any) => {
        //     return addition.controls.map((control: any) => {
        //       return {
        //         ...control,
        //         isAddition: true,
        //         category_name: addition.name,
        //       };
        //     });
        //   }) ?? [];
        // const controls = [...options, ...additionals.flat()];
        setMealName(ucfirst(data.name));
        setLabels(data?.labels ?? []);
        setImageUrlS3(data?.imageUrlS3 ?? "");
        setDescription(ucfirst(data?.description));

        const formattedProduct = {
          ...data,
          productBaseVariants: data?.productBaseVariants.map((variant: any) => {
            const grouped = variant.productExtensionVariant.reduce(
              (acc: any, variant: any) => {
                const categoryId = variant.productExtension.category.id;
                if (!acc[categoryId]) {
                  acc[categoryId] = {
                    category: variant.productExtension.category,
                    variants: [],
                  };
                }
                acc[categoryId].variants.push({
                  id: variant.id,
                  name: variant.productExtension.name,
                  priceExtension: variant.priceExtension,
                  productExtension: variant.productExtension,
                  menuPosition: variant.productExtension.menuPosition,
                });
                return acc;
              },
              {} as Record<number, { category: any; variants: any[] }>
            );

            return {
              ...variant,
              groupedProductExtensionVariants: Object.values(grouped),
            };
          }),
        };

        const groupedVariants =
          formattedProduct.productBaseVariants[0]
            .groupedProductExtensionVariants ?? [];

        setExtentions(groupedVariants ?? []);
        const additionals =
          groupedVariants?.map((group: any) => {
            if (group.category.type === "QUANTITIES") {
              return group.variants.map((variant: any) => {
                return {
                  field_type: "addition",
                  uuid: `addition-${variant.id}`,
                  field_name: variant.productExtension.name,
                  price: variant.priceExtension ?? 0,
                  value: variant.productExtension.minimumRequiredQuantity,
                  getFromStorage: false,
                  isAddition: true,
                  category_name: group.category.name,
                };
              });
            } else {
              return [
                {
                  field_type: "additionSelect",
                  uuid: `additionSelect-${group.category.id}`,
                  minValue: group.category.minimumRequiredElements,
                  field_name: group.category.name,
                  getFromStorage: false,
                  isAddition: true,
                },
              ];
            }
          }) ?? [];
        const controls = [...options, ...additionals.flat()];
        setControls(controls);
        setFinalPrice([
          {
            id: "BASE_PRICE",
            value: data?.productBaseVariants[0].price,
          },
        ]);
      },
    }
  );

  const schema = createShema(controls);
  const methods = useForm({
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    if (controls.length > 0) {
      methods.reset(createFormInitialValue(controls));
    }
    return;
  }, [controls, selectedProduct]);

  const handleOk = () => {
    setSelectedProduct(null);
  };

  const handleCancel = () => {
    setSelectedProduct(null);
  };

  return (
    <FormProvider {...methods}>
      <StyledModal
        $height={height}
        style={{
          top: 50,
        }}
        open={!!selectedProduct}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <AddMealAction
            finalPrice={finalPrice}
            controls={controls}
            variant={variant}
            id={selectedProduct?.id}
          />
        }
        closable={false}
        destroyOnClose
      >
        {isFetching ? (
          <Skeleton active />
        ) : (
          <ProductDetails
            meal={selectedProduct}
            finalPrice={finalPrice}
            setFinalPrice={setFinalPrice}
            variant={variant}
            extentions={extentions}
            setVariant={setVariant}
            description={description}
            imageUrlS3={imageUrlS3}
            labels={labels}
          />
        )}
      </StyledModal>
    </FormProvider>
  );
};
