import { Button, Flex, Input, Space } from "antd";
import React, { useState } from "react";

import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useAppContext } from "../../../contexts/useAppContext";

const QuantityWrapper = styled.div`
  max-width: 120px !important;
  width: 120px !important;
  min-width: 120px !important;
  background: #f3f4f6;
  border-radius: 40px;
`;

const Meal = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 1px 2px 0px 0px #edebfb;
  padding: 16px;
`;

const CostumButton = styled(Button)`
  max-width: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  max-height: 40px !important;
  height: 40px !important;
  background: #f3f4f600;
`;

const AdditionPrice: React.FC<{ price: any }> = ({ price }) => {
  const converderdPrice = price / 100;
  return <>{`${converderdPrice.toFixed(2)} zł`}</>;
};

export const CartPosition: React.FC<{ meal: any }> = ({ meal }) => {
  const { setCart, cart, selectedBranch } = useAppContext();
  const description = cart.find((e: any) => e.local_id === meal.local_id)
    .cart_positoin?.description;
  const isShop = selectedBranch.sellType === "DIGITAL_PRODUCTS_SELLER";

  const [showDescription, setShowDescription] = useState(!!description);

  const changeQuantity = (direction: any, id: any) => {
    if (direction) {
      setCart((cart: any) => {
        const newCart = JSON.parse(JSON.stringify(cart));
        const meal = newCart.find((e: any) => e.local_id === id);
        meal.cart_positoin.meal_quantity = meal.cart_positoin.meal_quantity + 1;

        return [...newCart];
      });
    } else {
      setCart((cart: any) => {
        const newCart = JSON.parse(JSON.stringify(cart));
        const meal = newCart.find((e: any) => e.local_id === id);
        meal.cart_positoin.meal_quantity = meal.cart_positoin.meal_quantity - 1;
        return [...newCart];
      });
    }
  };

  const deletePosition = (id: any) => {
    setCart((cart: any) => {
      const newCart = JSON.parse(JSON.stringify(cart));

      return [...newCart.filter((e: any) => e.local_id !== id)];
    });
  };

  const addDescription = (value: any, id: any) => {
    setCart((cart: any) => {
      const newCart = JSON.parse(JSON.stringify(cart));
      const meal = newCart.find((e: any) => e.local_id === id);
      meal.cart_positoin.description = value;

      return [...newCart];
    });
  };

  const deleteDescription = (id: any) => {
    setCart((cart: any) => {
      const newCart = JSON.parse(JSON.stringify(cart));
      const meal = newCart.find((e: any) => e.local_id === id);
      delete meal.cart_positoin.description;

      return [...newCart];
    });
  };

  return (
    <Meal>
      <Flex
        justify="space-between"
        style={{
          marginBottom: "5px",
        }}
      >
        <Space>
          <P $size="16" $height="20" $weight="400">
            {meal.cart_positoin.meal_name}
          </P>
        </Space>
      </Flex>
      <Flex vertical>
        {meal.cart_positoin.meal_options.map(({ controls }: any) => {
          return controls.map((control: any) => {
            return (
              <>
                <Space
                  style={{
                    columnGap: "4px",
                  }}
                >
                  <span>
                    <P
                      $size="14"
                      $height="16"
                      $weight="400"
                      $family="Lato-Light"
                    >
                      {control.name}:
                    </P>
                  </span>
                  <span>
                    <P
                      $size="14"
                      $height="20"
                      $weight="300"
                      $family="Lato-Light"
                    >
                      {control.value}
                    </P>
                  </span>
                </Space>
              </>
            );
          });
        })}
      </Flex>
      <Flex
        style={{
          maxHeight: "40px",
          marginTop: "30px",
        }}
        justify={isShop ? "end" : "space-between"}
        align="center"
      >
        {!isShop && (
          <Button
            type="link"
            style={{
              paddingLeft: 0,
              color: showDescription ? "#b91c1c" : "#4F46E5",
              textDecoration: "underline",
            }}
            onClick={() => {
              if (showDescription) {
                deleteDescription(meal.local_id);
              }
              setShowDescription((show: any) => {
                return !show;
              });
            }}
          >
            <P $size="12" $height="16" $weight="400">
              {showDescription ? "Usuń notatkę" : "Dodaj notatkę"}
            </P>
          </Button>
        )}

        <Flex gap={8}>
          <Space>
            <P $size="16" $height="20" $weight="400" $family="Lato">
              <AdditionPrice
                price={
                  meal.cart_positoin.meal_price *
                  meal.cart_positoin.meal_quantity
                }
              />
            </P>
          </Space>
          <QuantityWrapper>
            <Flex
              style={{
                maxHeight: "40px",
              }}
              justify={"space-between"}
              align="center"
            >
              <>
                <CostumButton
                  style={{
                    borderColor: "#4F46E500",
                  }}
                  onClick={() => {
                    if (meal.cart_positoin.meal_quantity > 1) {
                      changeQuantity(false, meal.local_id);
                    } else {
                      deletePosition(meal.local_id);
                    }
                  }}
                  danger
                  shape="circle"
                  icon={
                    meal.cart_positoin.meal_quantity > 1 ? (
                      <MinusOutlined
                        style={{
                          color: "#B91C1C",
                        }}
                      />
                    ) : (
                      <DeleteOutlined
                        style={{
                          color: "#B91C1C",
                        }}
                      />
                    )
                  }
                />
                <P $height="24" $size="18" $weight="400">
                  {meal.cart_positoin.meal_quantity}
                </P>
              </>

              <CostumButton
                onClick={() => {
                  changeQuantity(true, meal.local_id);
                }}
                style={{
                  borderColor: "#4F46E500",
                }}
                shape="circle"
                icon={
                  <PlusOutlined
                    style={{
                      color: "#4F46E5",
                    }}
                  />
                }
              />
            </Flex>
          </QuantityWrapper>
        </Flex>
      </Flex>
      {showDescription && (
        <Flex
          style={{
            maxHeight: "70px",
            minHeight: "70px",
            height: "70px",
            marginTop: "24px",
          }}
          justify="space-between"
          align="center"
        >
          <Input.TextArea
            onChange={(e) => {
              addDescription(e.target.value, meal.local_id);
            }}
            value={description}
            style={{
              maxHeight: "70px",
              minHeight: "70px",
              height: "70px",
              resize: "none",
            }}
          />
        </Flex>
      )}
    </Meal>
  );
};
