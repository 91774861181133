import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { motion } from "framer-motion";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { Button, Flex } from "antd";
import { useLocation } from "react-router-dom";

const CostumAddButton = styled(Button)`
  margin-top: 30px;
  max-width: 100% !important;
  width: 70% !important;
  height: 48px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: white !important;
    border-color: #4f46e5 !important;
  }
`;

export const ToPayPage: React.FC = () => {
  useScrollToTop();
  const location = useLocation();
  const url = location?.state?.url;
  const animations = {
    initial: { opacity: 0, x: "10vw" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
  };

  if (!url) return null;

  return (
    <>
      <AnimadetPage>
        <div>
          <motion.div
            variants={animations}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <Flex
              justify="center"
              align="center"
              style={{
                height: "calc(100vh - 44px)",
                width: "100%",
                flexDirection: "column",
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
            >
              <P
                $size="20"
                $weight="600"
                $height="16"
                style={{
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                Dziękujemy za Twój zakup!
              </P>
              <P
                $size="16"
                $weight="300"
                $height="16"
                style={{
                  textAlign: "center",
                }}
              >
                Jeśli nie nastąpiło przekierwoanie do strony Przelewy24 naciśnij
                przycsik "Zapłać" aby dokończyć proces składania zamówienia.
              </P>
              <CostumAddButton href={url}>
                <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                  Zapłać
                </P>
              </CostumAddButton>
            </Flex>
          </motion.div>
        </div>
      </AnimadetPage>
    </>
  );
};
