import styled from "styled-components";

export const Label = styled.div<{ $showLabel: boolean }>`
  display: flex;
  height: 24px;
  padding: ${({$showLabel})=> $showLabel ? "4px 8px": "4px 4px" };
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--gray-100, #f3f4f6);
  background: var(--color-grayscale-white, #fff);
`;
