import { Avatar, Button, Drawer, Flex, Space, Modal, Alert } from "antd";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import styled from "styled-components";
import CaretLeft from "../../../images/CaretLeft.svg";
const headerStyle: React.CSSProperties = {
  height: "100%",
  paddingLeft: "8px",
  paddingRight: "8px",
  maxWidth: "800px",
  width: "100%",
};

const CostumButton = styled(Button)`
  max-height: 35px !important;
  height: 35px !important;
  border-radius: 30px;
  color: #4f46e5;
  &:hover {
    color: #4f46e5 !important;
    border-color: #4f46e5 !important;
  }
`;

export const Header: React.FC = () => {
  const {
    selectedBranch,
    isMain,
    setIsMain,
    setCart,
    setDeliveryType,
    setDeliveryTimeType,
    setOrderDate,
    setOrderTime,
    setServiceData,
    setServiceTime,
    setServiceResurces,
    setSelectedDeliveryMethod,
    cart,
  } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showFirstDiv, setShowFirstDiv] = useState(true);
  const { mealName, serviceData } = useAppContext();
  // const [open, setOpen] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/menu" && location.pathname !== "/services") {
      setIsCollapsed(true);
    } else {
      setTimeout(() => {
        setIsCollapsed(false);
        setShowFirstDiv(true);
      }, 300);

      setIsCollapsed(false);
    }
  }, [location]);

  const additionalButton =
    selectedBranch?.config?.welcome_screen?.additional_button;

  const mainButton = selectedBranch?.config?.welcome_screen?.main_button;

  const redirect = () => {
    setCart([]);
    setDeliveryType("TO_THE_ADDRESS");
    setDeliveryTimeType("NOW");
    setOrderDate(null);
    setOrderTime(null);
    setServiceData(null);
    setServiceTime(null);
    setServiceResurces({});
    setSelectedDeliveryMethod(null);
    setIsMain(!isMain);
    navigate(isMain ? additionalButton?.navigate : mainButton?.navigate, {
      state: { from: "home" },
    });
  };
  if (location.pathname !== "/" && location.pathname !== "/statute") {
    return (
      <>
        <motion.div
          style={{
            display: "flex",
            justifyContent: "center",
            background: "#f5f5f5",
            borderBottom: "2px solid #e5e7eb",
          }}
          initial={{ height: "64px" }}
          animate={{ height: isCollapsed ? "64px" : "64px" }}
          transition={{ duration: 0.3 }}
        >
          <Flex
            style={headerStyle}
            align="center"
            justify="space-between"
            gap={showFirstDiv ? 8 : 0}
          >
            <Flex
              // style={headerStyle}
              align="center"
              justify="space-between"
              gap={showFirstDiv ? 8 : 0}
            >
              {!showFirstDiv && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isCollapsed && !showFirstDiv ? 1 : 0 }}
                  transition={{ duration: 0.3, delay: 0 }}
                >
                  {location.pathname !== "/thank-you" && (
                    <Flex align="center">
                      <Button
                        type="text"
                        onClick={() => navigate(-1)}
                        style={{
                          paddingTop: "6px",
                        }}
                        icon={<img src={CaretLeft} />}
                      ></Button>
                      {/* {mealName?.charAt(0)?.toUpperCase() + mealName?.slice(1)} */}
                      {location.pathname === "/cart" && "Koszyk"}
                      {location.pathname === "/delivery" && "Dane dostawy"}
                      {location.pathname === "/summary" && "Podsumowanie"}
                    </Flex>
                  )}
                </motion.div>
              )}
              {showFirstDiv && (
                <motion.div
                  initial={{ opacity: 1, x: 0, y: 0 }}
                  animate={{
                    opacity: isCollapsed ? 0 : 1,
                    x: isCollapsed ? "-10px" : 0,
                    y: isCollapsed ? "-5px" : 0,
                  }}
                  transition={{ duration: 0.3 }}
                  onAnimationComplete={() => {
                    setShowFirstDiv(!isCollapsed);
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <P $size="14" $height="16" $weight="400">
                    {selectedBranch && selectedBranch.name}
                  </P>
                  <P $size="12" $height="20" $weight="300" $family="Lato-Light">
                    {selectedBranch && selectedBranch?.description}
                  </P>
                </motion.div>
              )}
            </Flex>
            {!!additionalButton &&
              !!mainButton &&
              (location.pathname === "/menu" ||
                location.pathname === "/services") && (
                <CostumButton
                  onClick={() => {
                    if (cart?.length === 0) {
                      redirect();
                    } else {
                      Modal.info({
                        title: "Twój koszyk zostanie oprózniony",
                        content: (
                          <div>
                            <p>Czy na pewno chcesz przejść?</p>
                          </div>
                        ),
                        closable: true,
                        onOk() {
                          redirect();
                        },
                        okText: "Potwierdz",
                      });
                    }
                  }}
                  style={{
                    borderColor: "#4F46E5",
                    background: "#f5f5f5",
                  }}
                >
                  {isMain ? additionalButton?.text : mainButton?.text}
                </CostumButton>
              )}
          </Flex>
          {/* <Drawer
          onClose={() => setOpen(false)}
          open={open}
          style={{
            zIndex: 3000,
          }}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Drawer> */}
        </motion.div>

      </>
    );
  }

  return null;
};
