import { Flex } from "antd";
import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { SingleMeal } from "../single-meal/SingleMeal";
import { useNavigate } from "react-router-dom";
import { getResourceCategories } from "../../../service";
import { useQuery } from "react-query";

const ListMealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;
const MealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

const GridMealsWrapper = styled(Flex)``;

// const MealsWrapper = ({
//   viewType,
//   children,
// }: {
//   viewType: "LIST" | "GRID";
//   children: React.ReactNode;
// }) => {
//   switch (viewType) {
//     case "LIST":
//       return <ListMealsWrapper>{children}</ListMealsWrapper>;
//     case "GRID":
//       return (
//         <GridMealsWrapper wrap="wrap" gap={8}>
//           {children}
//         </GridMealsWrapper>
//       );
//     default:
//       return <ListMealsWrapper>{children}</ListMealsWrapper>;
//   }
// };

export const SingleServiceCategory: React.FC<{
  category: any;
  preventScroll: any;
  resourceCategories: any;
}> = ({ category, preventScroll, resourceCategories }) => {
  const navigate = useNavigate();

  const {
    setScroll,
    setCart,
    setMealName,
    selectedBranch,
    setSelectedCategory,
  } = useAppContext();

  const viewType = selectedBranch?.viewType ?? "LIST";

  const onClickChandler = (meal: any) => {
    setScroll(window.scrollY);

    setMealName(meal.name);
    if (resourceCategories.length > 0) {
      navigate(`/resources`, { state: { from: "home", mealId: meal.id } });
    } else {
      navigate(`/reservate/${meal.id}`, {
        state: { from: "home", mealId: meal.id },
      });
    }

    setCart([
      {
        cart_positoin: {
          meal_quantity: 1,
          meal_name: meal.name,
          meal_options: [],
          meal_price: meal.price,
          id: meal.id,
        },
      },
    ]);
  };

  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        // Obliczenie widocznej części elementu
        const visibleHeight =
          Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
        if (
          (rect.top > 0 && rect.top < 200) ||
          visibleHeight === windowHeight
        ) {
          setSelectedCategory(category);
        }
      }
    };
    if (!preventScroll) window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [preventScroll]);

  return (
    <div ref={elementRef}>
      <>
        <P
          $size="18"
          $height="24"
          $weight="600"
          style={{
            paddingLeft: "12px",
            paddingTop: "24px",
            paddingBottom: "5px",
          }}
          id={category?.id}
        >
          {category?.name}
        </P>
        <P
          $size="14"
          $weight="300"
          $height="20"
          $family="Lato-Light"
          style={{
            paddingLeft: "12px",
            paddingTop: "5px",
            paddingBottom: "26px",
          }}
          id={category?.id}
        >
          {category?.descripotion}
        </P>
        <MealsWrapper>
          {category.services
            ?.sort((a: any, b: any) => a.menuPosition - b.menuPosition)
            ?.map((service: any) => {
              return (
                <SingleMeal
                  type={"SERVICE"}
                  key={service.id}
                  meal={service}
                  onClick={onClickChandler}
                />
              );
            })}
        </MealsWrapper>
      </>
    </div>
  );
};
